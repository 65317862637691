import { Injectable } from '@angular/core';
declare var googletag: any;

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor() { }

  /**
   * Esta función valida si el slot existe
   *  - Si existe, lo despliega de nuevo.
   *  - Si no existe, lo crea, se configura y se renderiza.
   * Adicionalmente, tiene un setTimeout para garantizar que cargue completamente en caso de entrar en un ngIf o similar.
   * @param slot Equivale al ID(slot) que hace referencia al configurado en el script de Google Ads.
   * @param id Este id hace referencia al ID del div donde se renderizará el banner.
   */
  addPubliById(slot: string, id: string) {
    setTimeout(()=>{
      googletag.cmd.push(() => {
        var slotAdd = googletag.pubads().getSlots().find((x:any) => x.getSlotId().getId() == slot+"_0");
        if(slotAdd == undefined){
          googletag.defineSlot(slot, [[728, 90], [300, 250]], id).addService(googletag.pubads());
          googletag.pubads().enableSingleRequest();
          googletag.enableServices();
          googletag.display(id);
        }else{
          googletag.pubads().refresh([slotAdd]);
        }
      });
    },100);
  }
}
