import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { User, UserData } from '../models/user.model';


@Injectable({
  providedIn: 'root'
})
export class SesionService {

  cookieValue: any = null;
  product: any;
  codeApp: any;
  utmCampaign: any;
  logo: any;

  // User
  user: any = null;
  urlVolver: any = null;
  userData: any = null;

  // GENERALES
  cookies = false;
  redirect: any;
  utmc: any;
  service: any;

  // NEW SSO
  tipoDoc: any;
  numDoc: any;
  tipo: any;
  autogestion = 0;

  idSessionTemp = '';
  acceptAdvertising = false;

  constructor(
    private titleService: Title,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute,
    public router: Router) {
    this.cookieValue = this.cookieService.get('IDSESSIONMDC');
  }

  setCodeApp() {
    switch (this.product) {
      case 'bc':
        this.codeApp = environment.codesApps.bc;
        this.urlVolver = environment.validLogin_bc;
        break;
      case 'midc':
        this.codeApp = environment.codesApps.midc;
        this.cookieValue ? this.urlVolver = environment.validLogin_midc : this.urlVolver = environment.validLogin_midc;
        if (!this.cookies) {
          this.activatedRoute.queryParams.subscribe(params => {

            if (params['service']) {
              this.setCookieService('SERVICE', params['service']);
              this.service = params['service'];
            }

            if (params['utm_campaign']) {
              this.setCookieService('UTMC', params['utm_campaign']);
              this.utmc = params['utm_campaign'];
            }

            if (params['redirect']) {
              this.setCookieService('REDIRECT', params['redirect']);
              this.redirect = params['redirect'];
            }

          });
          this.cookies = true;
        }
        break;
      case 'pntd':
        this.codeApp = environment.codesApps.pntd;
        this.urlVolver = environment.validLogin_pntd;
        break;
      case 'vrf':
        this.codeApp = environment.codesApps.vrf;
        this.urlVolver = environment.validLogin_vrf;
        break;
      case 'os':
        this.codeApp = environment.codesApps.midc;
        this.urlVolver = environment.validLogin_midc;
        break;
    }
  }

  navigateToRoute(path: any) {
    this.activatedRoute.queryParams.subscribe(params => {
      // tslint:disable-next-line: max-line-length
      params ? this.router.navigate([path], { queryParams: params }) : this.router.navigate([path], { queryParams: params });
    });
  }

  setTitle(title: string) {
    this.titleService.setTitle(title);
  }

  setCookie(token: string) {
    this.cookieService.set('IDSESSIONMDC', token, undefined, '/', environment.domain, true);
    this.cookieValue = this.cookieService.get('IDSESSIONMDC');
  }

  getCookie() {
    this.cookieValue = this.cookieService.get('IDSESSIONMDC');
    return this.cookieValue;
  }

  setCookieService(service: string, value: string) {
    this.cookieService.set(service, value, undefined, '/', environment.domain, true);
  }
  getCookieServices(service: string) {
    return this.cookieService.get(service);
  }
  deleteCookieServices(service: string, value: string) {
    this.cookieService.delete(service, value, environment.domain);
  }

  deleteCookie() {
    this.cookieService.delete('IDSESSIONMDC', this.cookieValue, environment.domain);
  }

  closeSession() {
    localStorage.clear();
    this.deleteCookie();
    this.cookieService.deleteAll('/');
    this.router.navigate(['/login'], { queryParams: { product: this.product }, queryParamsHandling: 'preserve' });
  }

  isEmpty(obj:any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
}

