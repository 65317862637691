import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router} from '@angular/router';
import { SesionService } from './sesion.service';
import { BrokerService } from './broker.service';
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { ProductService } from './product.service';

@Injectable()
export class CanActiveViaAuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private sesion: SesionService,
    public broker: BrokerService,
    private product: ProductService,
    @Inject(APP_CONFIG) private config: AppConfig) {
    }

  canActivate(route: ActivatedRouteSnapshot) {

    const page = route.url[0].path;
    const product = route.queryParams['product'];
    const utm_campaign = route.queryParams['utm_campaign'];
    const logo = route.queryParams['log'];
    
    if(page == 'registro' && route.queryParams['auto'] ==1) {
      return false;
    }
    if ( product ) {
      // Validar producto existente
      const indexProduct = this.config.products.findIndex( p => p === product  );

      if (indexProduct === -1 ) {
        this.router.navigate(['/login'], {queryParams: {product: 'midc'}});
        return false;
      } else {

        if (!this.isLoggedIn()) {
          const indexNoSession = this.config.pages[indexProduct].nosession.findIndex( p => p === page  );
          if (indexNoSession === -1 ) {
            this.router.navigate(['/login'], {queryParams: {product: product}});
            return false;
          } else {
            if (this.sesion.product !== product) {
              this.sesion.product = product;
              this.sesion.utmCampaign = utm_campaign;
              this.sesion.logo = logo;
              this.product.producto = product;
              this.sesion.setCodeApp();
              this.product.obtenerContenido();
            }
            return true;
          }
        } else {
          const indexSession = this.config.pages[indexProduct].session.findIndex( p => p === route.url.toString()  );
          if (indexSession === -1 ) {
            if(indexProduct === 4){
              this.router.navigate(['/oneshot/payments'], { queryParams: {product : product} });
            }
            else {
              this.router.navigate(['/private/perfil'], {queryParams: {product: product}});
            }     
            return false;
          } else {
            if (this.sesion.product !== product) {
              this.sesion.product = product;
              this.sesion.utmCampaign = utm_campaign;
              this.sesion.logo = logo;
              this.product.producto = product;
              this.sesion.setCodeApp();
              this.product.obtenerContenido();
            }
            return true;
          }
        }
      }

    } else {
      this.router.navigate(['/' + page], {queryParams: {product: 'midc'}});
      return false;
    }
  }

  isLoggedIn(): boolean {
    this.sesion.getCookie();
    if ( this.sesion.cookieValue ) {
      return true;
    } else {
      this.sesion.deleteCookie();
      return false;
    }
  }
}
